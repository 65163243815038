import React, {Suspense, lazy} from "react";
import {Route, Routes} from "react-router-dom";

const AKAHoldingPage = lazy(() => import("./views/AKAHoldingPage"));
// const AKAProductionsPage = lazy(() => import("./views/AKAProductionsPage"));
// const AKAAcademyPage = lazy(() => import("./views/AKAAcademyPage"));
const AKAWorkPage = lazy(() => import("./views/AKAWorkPage"));
const AKAPrivacyPage = lazy(() => import("./views/AKAPrivacyPage"));
// const AKACookiePage = lazy(() => import("./views/AKACookiePage"));
// const AKATermsPage = lazy(() => import("./views/AKATermsPage"));

export default function Routing() {
	let _path = "/";

	return (
		<Suspense fallback={<div className="loading">Loading...</div>}>
			<Routes>
				<Route
					exact
					path={`${_path}`}
					element={
						<Suspense
							fallback={
								<>
									<div className="loading">Loading...</div>
								</>
							}
						>
							{" "}
							<AKAHoldingPage />{" "}
						</Suspense>
					}
				/>
				{/* <Route
					exact
					path={`${_path}productions`}
					element={
						<Suspense
							fallback={
								<>
									<div className="loading">Loading...</div>
								</>
							}
						>
							{" "}
							<AKAProductionsPage />{" "}
						</Suspense>
					}
				/>
				<Route
					exact
					path={`${_path}jlc-academy`}
					element={
						<Suspense
							fallback={
								<>
									<div className="loading">Loading...</div>
								</>
							}
						>
							{" "}
							<AKAAcademyPage />{" "}
						</Suspense>
					}
				/>*/}
				<Route
					exact
					path={`${_path}work-with-jlc`}
					element={
						<Suspense
							fallback={
								<>
									<div className="loading">Loading...</div>
								</>
							}
						>
							{" "}
							<AKAWorkPage />{" "}
						</Suspense>
					}
				/> 
				<Route
					exact
					path={`${_path}privacy-policy`}
					element={
						<Suspense
							fallback={
								<>
									<div className="loading">Loading...</div>
								</>
							}
						>
							{" "}
							<AKAPrivacyPage />{" "}
						</Suspense>
					}
				/>
				{/* <Route
					exact
					path={`${_path}cookie-policy`}
					element={
						<Suspense
							fallback={
								<>
									<div className="loading">Loading...</div>
								</>
							}
						>
							{" "}
							<AKACookiePage />{" "}
						</Suspense>
					}
				/>
				<Route
					exact
					path={`${_path}terms-and-conditions`}
					element={
						<Suspense
							fallback={
								<>
									<div className="loading">Loading...</div>
								</>
							}
						>
							{" "}
							<AKATermsPage />{" "}
						</Suspense>
					}
				/> */}
			</Routes>
		</Suspense>
	);
}
